import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ddad346 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _6206bff7 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _ecb07cb8 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _8b09f99a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5486e096 = () => interopDefault(import('../pages/reservation.vue' /* webpackChunkName: "pages/reservation" */))
const _21ac5731 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _3d815f84 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _9a71249a = () => interopDefault(import('../pages/account/notification.vue' /* webpackChunkName: "pages/account/notification" */))
const _d5ef997e = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _10a99f63 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _d297b55c = () => interopDefault(import('../pages/reservations/history.vue' /* webpackChunkName: "pages/reservations/history" */))
const _7afb4dd0 = () => interopDefault(import('../pages/reservations/list.vue' /* webpackChunkName: "pages/reservations/list" */))
const _b2e66ce0 = () => interopDefault(import('../pages/service/contact.vue' /* webpackChunkName: "pages/service/contact" */))
const _635f49e6 = () => interopDefault(import('../pages/service/coupon.vue' /* webpackChunkName: "pages/service/coupon" */))
const _b6b16fda = () => interopDefault(import('../pages/service/news.vue' /* webpackChunkName: "pages/service/news" */))
const _5baf1458 = () => interopDefault(import('../pages/service/notifications.vue' /* webpackChunkName: "pages/service/notifications" */))
const _048e5610 = () => interopDefault(import('../pages/service/privacy.vue' /* webpackChunkName: "pages/service/privacy" */))
const _5acbb977 = () => interopDefault(import('../pages/service/terms.vue' /* webpackChunkName: "pages/service/terms" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _1ddad346,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/forget_password/",
    component: _6206bff7,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _ecb07cb8,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _8b09f99a,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reservation/",
    component: _5486e096,
    pathToRegexpOptions: {"strict":true},
    name: "reservation"
  }, {
    path: "/reset_password/",
    component: _21ac5731,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/signup/",
    component: _3d815f84,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/account/notification/",
    component: _9a71249a,
    pathToRegexpOptions: {"strict":true},
    name: "account-notification"
  }, {
    path: "/account/overview/",
    component: _d5ef997e,
    pathToRegexpOptions: {"strict":true},
    name: "account-overview"
  }, {
    path: "/account/password/",
    component: _10a99f63,
    pathToRegexpOptions: {"strict":true},
    name: "account-password"
  }, {
    path: "/reservations/history/",
    component: _d297b55c,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-history"
  }, {
    path: "/reservations/list/",
    component: _7afb4dd0,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-list"
  }, {
    path: "/service/contact/",
    component: _b2e66ce0,
    pathToRegexpOptions: {"strict":true},
    name: "service-contact"
  }, {
    path: "/service/coupon/",
    component: _635f49e6,
    pathToRegexpOptions: {"strict":true},
    name: "service-coupon"
  }, {
    path: "/service/news/",
    component: _b6b16fda,
    pathToRegexpOptions: {"strict":true},
    name: "service-news"
  }, {
    path: "/service/notifications/",
    component: _5baf1458,
    pathToRegexpOptions: {"strict":true},
    name: "service-notifications"
  }, {
    path: "/service/privacy/",
    component: _048e5610,
    pathToRegexpOptions: {"strict":true},
    name: "service-privacy"
  }, {
    path: "/service/terms/",
    component: _5acbb977,
    pathToRegexpOptions: {"strict":true},
    name: "service-terms"
  }, {
    path: "/",
    redirect: "/reservation/",
    name: "index"
  }, {
    path: "*",
    redirect: "/reservation/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}

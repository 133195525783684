export const getCenterCoordinates = () => {
  const center: any = {
    lat: 38.059400,
    lng: 139.410272
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 14
  return zoom
}
